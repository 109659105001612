/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import Portals from 'modules/common/Portals';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {compose, withStateHandlers} from 'recompose';

const ModalButton = ({
  children,
  className = '',
  modalContent,
  modalVisible,
  toggleModal,
}) => (
  <div>
    <button
      aria-label="Toggle modal"
      className={className}
      onClick={toggleModal}
    >
      {children}
    </button>
    {modalVisible && (
      <Portals>
        <div className="flex items-center justify-center fixed h-100 w-100 top-0 left-0 bg-black-90 z-9999">
          <div className="h-100 w-100" onClick={toggleModal} />
          <div className="w5 w-70-m w-50-l absolute center-absolute br2 pa3 bg-white">
            {modalContent}
            <button
              aria-label="close"
              className="mh2 ph5-ns ph3 pv2 bg-green white f5 b br-pill b--none pointer grow"
              onClick={toggleModal}
            >
              <FormattedMessage id="close" />
            </button>
          </div>
        </div>
      </Portals>
    )}
  </div>
);

ModalButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  modalContent: PropTypes.node.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
const enhance = compose(
  withStateHandlers(
    {modalVisible: false},
    {
      toggleModal: ({modalVisible}) => () => ({modalVisible: !modalVisible}),
    },
  ),
);
export default enhance(ModalButton);
