// eslint-disable-next-line import/prefer-default-export
export const sendAnalytics = (provider) => {
  const data = new FormData();
  data.append('provider', provider);
  return {
    types: [
      'CREATE_ANALYTICS_REQUEST',
      'CREATE_ANALYTICS_SUCCESS',
      'CREATE_ANALYTICS_FAILURE',
    ],
    config: {
      method: 'post',
      url: 'referral/analytics/',
      data,
    },
  };
};
