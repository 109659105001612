import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {avatarSize, bgFileInput, formInput} from './styles.scss';

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      thumbnail: props.user.avatar_url,
    };
  }

  handleChange = (e) => {
    const {onChange, user} = this.props;
    if (onChange) onChange(e);
    if (e.target.files[0] && e.target.files[0].name) {
      this.setState({
        value: e.target.value.split(/(\\|\/)/g).pop(),
        thumbnail: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      this.setState({
        value: e.target.value.split(/(\\|\/)/g).pop(),
        thumbnail: user.avatar_url,
      });
    }
  };

  render() {
    const {
      user,
      label,
      autoComplete,
      intl: {formatMessage},
    } = this.props;
    const {thumbnail, value} = this.state;
    return (
      <>
        <div className="w-30-ns">
          <img
            className={`br-100 ${avatarSize}`}
            alt={user.first_name}
            src={thumbnail}
          />
        </div>
        <div>
          <label className="b f6">{label}</label>
          <div className="pv3">
            <div
              className={`ph2 pv1 ${bgFileInput} w-100 br2 ba b--transparent`}
            >
              <div className="relative flex flex-row-ns flex-column pr1">
                <input
                  type="file"
                  onChange={this.handleChange}
                  className="absolute o-0"
                  accept=".jpg,.png"
                  autoComplete={autoComplete}
                />
                <label className="h-100 pv2 w-60 tc bg-white ba b--moon-gray br2 ph2">
                  <FormattedMessage id="upload_picture" />
                </label>
                <input
                  type="text"
                  value={value}
                  className={`relative ba b--transparent mr1-ns pl4-ns ${formInput}`}
                  placeholder={
                    thumbnail ||
                    formatMessage({
                      id: 'no_file_chosen',
                    })
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

FileInput.propTypes = {
  autoComplete: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  intl: PropTypes.object,
};

const FileInputField = ({
  field, // { name, value, onChange, onBlur }
  form: {setFieldValue}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <FileInput
    {...field}
    onChange={(event) => {
      setFieldValue(field.name, event.target.files[0]);
      setFieldValue(
        field.name + 'Path',
        event.target.value.replace(/\\/g, '').replace(/C:fakepath/g, ''),
      );
    }}
    {...props}
  />
);

FileInputField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default injectIntl(FileInputField);
