import {Mutation} from '@apollo/client/react/components';
import compose from 'core/libs/helpers/compose.js';
import notification from 'core/libs/helpers/notification';
import redirectIfPartner from 'core/libs/hoc/redirectIfPartner';
import {navigate} from 'gatsby';
import Container from 'modules/common/Container';
import GoogleButton from 'modules/common/GoogleButton';
import INVITE_GOOGLE_CONTACTS from 'modules/common/graphql/inviteGoogleContacts.graphql';
import ReferralShare from 'modules/common/ReferralFriends/components/ReferralShare';
import GoogleIcon from 'modules/common/Svg/GoogleIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import ReferralIntro from '../components/Referrals/ReferralIntro';
import {customHeader} from './styles.scss';

const {PARTNER} = process.env;

const ReferralInvite = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {dispatch, stats, invitationsResult} = props;
  return (
    <div>
      <Helmet
        title={`${formatMessage({
          id: 'referrals',
        })} | ${formatMessage({
          id: PARTNER === 'BARMEJ' ? 'helmet_barmej' : 'partner_helmet',
        })}`}
      />
      <div className="w-100 bg-darker-primary-blue tr-ns tc">
        <div className={`w-80-ns w-90 center ${customHeader} tc white`}>
          <h1 className="f3">
            <FormattedMessage id="referral_title" />
          </h1>
        </div>
      </div>
      <Container className="w-70-ns w-80-m w-100 center ba b--light-gray br2 ph4-ns ph3-m ph1 pb2 mv5">
        <ReferralIntro />
        <div className="center">
          <h2 className="f5 tc mb4 gray">
            <FormattedMessage id="refer_contacts" />
          </h2>
          <Mutation
            mutation={INVITE_GOOGLE_CONTACTS}
            onCompleted={() => {
              notification({
                id: 'refer_contacts_notification',
                type: 'success',
              });
              navigate('/tracks');
            }}
          >
            {(mutate, {loading, error}) => {
              if (loading)
                return (
                  <p className="ph2">
                    <FormattedMessage id="loading" />
                  </p>
                );
              if (error)
                return (
                  <p className="ph2">
                    <FormattedMessage id="server_error_try_again" />
                  </p>
                );
              return (
                <div className="w-100 w-25-ns center ph3 pv3 pv0-ns">
                  <GoogleButton
                    active={!loading}
                    clientId={process.env.GATSBY_GOOGLE_PLUS_CLIENT_ID}
                    scope="https://www.googleapis.com/auth/contacts.readonly"
                    callback={({access_token}) => {
                      if (access_token)
                        mutate({variables: {accessToken: access_token}});
                    }}
                    errorType="error while importing contacts"
                    className="w-100 bg-blue bg-animate hover-bg-dark-blue f6 f5-ns white pv3 ph3 ph4-l ba br2 pointer flex items-center justify-center button-reset mb0-ns mb2-m mb2"
                  >
                    <GoogleIcon className="ml2" width="16" height="16" />
                    Google
                  </GoogleButton>
                </div>
              );
            }}
          </Mutation>
        </div>
        <div className="pv5">
          <ReferralShare
            {...stats}
            dispatch={dispatch}
            invitationsResult={invitationsResult}
          />
        </div>
      </Container>
    </div>
  );
};

ReferralInvite.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stats: PropTypes.objectOf(PropTypes.any),
  invitationsResult: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => {
  return {
    stats: state.user.referrals.stats,
    invitationsList: state.user.referrals.invitationsList,
    invitationsResult: state.user.referrals.invitationsResult,
  };
};

const enhance = compose(connect(mapStateToProps), redirectIfPartner);

export default enhance(ReferralInvite);
