import LinkedInAddCertificateButton from 'modules/common/LinkedInAddCertificateButton';
import NewDesignButton from 'modules/common/NewDesignButton';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {compose, defaultProps} from 'recompose';
import download from 'theme/assets/images/download.svg';

const CertificationBox = ({
  title,
  trackTitle,
  payed,
  children,
  filePdf,
  id,
  className,
  trackIcon,
}) => {
  return (
    <div className={`w-third-ns w-50-m w-50-mid w-100 pv4 ph3 ${className}`}>
      <div className="ba b--light-gray bw1 br2 tc flex flex-column justify-between">
        <div>
          <div className="pv3">
            <img height="44" width="44" src={trackIcon} alt="track icon" />
          </div>
          <div className="pa3 pb0 b">
            <p className="f5">{title}</p>
            <p className="f5 pt2">{trackTitle}</p>
          </div>
        </div>
        <div className="pv5 ph3">{children}</div>
        <div className="pa3 f6 bg-near-white">
          {payed ? (
            <div className="flex items-center justify-center">
              {filePdf && (
                <NewDesignButton
                  className="pv3-ns ph2-ns bg-green-light bg-animate hover-bg-green"
                  link={filePdf}
                  external
                  attrs={{download: true}}
                >
                  <FormattedMessage id="download_certificate" />
                  <img src={download} className="ph1" alt="Download" />
                </NewDesignButton>
              )}
              <LinkedInAddCertificateButton
                className="mr2 pv3-ns ph2-ns"
                trackTitle={trackTitle}
              />
            </div>
          ) : (
            id && (
              <div>
                <NewDesignButton
                  className="pv3-ns ph2-ns bg-blue bg-animate hover-bg-dark-blue"
                  link={`/account/certificates/${id}`}
                >
                  <FormattedMessage id="obtain_certificate" />
                </NewDesignButton>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

CertificationBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  trackTitle: PropTypes.string.isRequired,
  payed: PropTypes.bool,
  id: PropTypes.number,
  filePdf: PropTypes.string,
  className: PropTypes.string,
  trackIcon: PropTypes.string,
};
const enhance = compose(
  defaultProps({
    payed: false,
    className: '',
  }),
);

export default enhance(CertificationBox);
