import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const CertificateIntro = ({title}) => {
  return (
    <div className="pv4">
      <h2 className="mb3">{title}</h2>
      <div className="cf">
        <p className="mw7-ns mw8-m mw-100 f5-ns f6 fr lh-copy gray">
          <FormattedMessage id="certificate_description" />
        </p>
      </div>
    </div>
  );
};

CertificateIntro.propTypes = {
  title: PropTypes.node.isRequired,
};

export default CertificateIntro;
