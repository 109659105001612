import notification from 'core/libs/helpers/notification';
import log from 'core/libs/logger';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {compose, lifecycle, setPropTypes, withHandlers} from 'recompose';

const GoogleButton = ({active, children, className, handleClick}) => {
  return (
    <div>
      <Helmet>
        <script
          src="https://apis.google.com/js/platform.js?onload=initGoogle"
          async=""
          defer=""
        />
      </Helmet>
      <button
        aria-label="Handle click"
        className={className}
        onClick={active ? handleClick : undefined}
        type="button"
      >
        {children}
      </button>
    </div>
  );
};

GoogleButton.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
};

const enhance = compose(
  setPropTypes({
    callback: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    errorType: PropTypes.string.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const {clientId, scope} = this.props;
      window.initGoogle = () =>
        window.gapi.load('auth2', () => {
          window.gapi.auth2.init({
            client_id: clientId,
            scope,
          });
        });
    },
  }),
  withHandlers({
    handleClick: ({callback, errorType, scope}) => () => {
      const access = () =>
        window.gapi.auth2
          .getAuthInstance()
          .signIn({scope})
          .then((res) => {
            const accessToken = res && res.tc && res.tc.access_token;
            callback({
              provider: 'google',
              access_token: accessToken,
            });
          })
          .catch((error) => {
            const errorMessage = error && error.error;
            callback({error});
            if (errorMessage !== 'popup_closed_by_user') {
              if (errorMessage !== 'popup_blocked_by_browser') {
                log(errorType, errorMessage);
              }
              notification({message: errorMessage});
            }
          });

      const delay = 2000;
      let retry = 0;
      const tryAccess = () => {
        if (window.gapi && window.gapi.auth2) {
          access();
        } else if (retry >= 10) {
          log(
            `google API didn't load after ${(retry * delay) / 1000} seconds`,
            {
              gapi: window.gapi,
            },
          );
        } else {
          retry += 1;
          setTimeout(tryAccess, delay);
        }
      };

      tryAccess();

      if (window && window.mixpanel) {
        window.mixpanel.track('Import and store gmail contacts click', {
          store_gmail_contacts_click: true,
        });
      }

      if (typeof window !== 'undefined') {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'Import and store gmail contacts click',
          });
        }
      }
    },
  }),
);

export default enhance(GoogleButton);
