import SocialShareLink from 'modules/common/SocialShareLink';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import getReferralsStats from '../actions';
import {divider} from './styles.scss';

class ReferralShare extends Component {
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getReferralsStats());
  }

  render() {
    const url = `https://barmej.com/?referral_key=${
      // eslint-disable-next-line react/prop-types
      this.props.data.referral_key
    }`;
    return (
      <div className="pv2">
        <div className={`${divider} mw5-ns mw6-m mw7 center bg-moon-gray`} />
        <div className="pv3">
          <h4 className="f5 tc mb4 gray">
            <FormattedMessage id="share_with_friends" />
          </h4>
          <div className="flex items-center justify-center pv3 mb3">
            <SocialShareLink
              url={url}
              dispatch={this.props.dispatch}
              isAnalytics
            />
          </div>
        </div>
      </div>
    );
  }
}

ReferralShare.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default ReferralShare;
