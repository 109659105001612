import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import SUBSCRIPTION from 'modules/user/graphql/subscription.graphql';
import INVOICES from 'modules/user/graphql/invoices.graphql';
import CurrentPlan from '../components/Billing/CurrentPlan';
import CurrentPaymentMethod from '../components/Billing/CurrentPaymentMethod';
import Invoices from '../components/Billing/Invoices';

const Billing = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {data, loading} = useQuery(SUBSCRIPTION, {
    fetchPolicy: 'cache-and-network',
  });
  const {fetchMore} = useQuery(INVOICES, {
    skip: invoices?.length > 0,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({recurrentPayment}) => {
      setInvoices(
        recurrentPayment?.invoices?.edges.map((invoice) => invoice.node),
      );
    },
  });
  const subscriptionData = data?.recurrentPayment;
  if (loading) return <LoadingIndicator />;
  if (!subscriptionData?.invoices) return <h1>لا توجد فواتير</h1>;

  const isActiveSubscription = subscriptionData?.status === 'ACTIVE';
  const invoicesLength = subscriptionData?.invoices?.edges.length;

  const loadMore = async (page) => {
    setIsLoadingMore(true);
    return await fetchMore({
      variables: {
        offset: page * 4,
      },
      updateQuery: (_, {fetchMoreResult}) => {
        setInvoices(
          fetchMoreResult?.recurrentPayment?.invoices?.edges.map(
            (invoice) => invoice.node,
          ),
        );
        setIsLoadingMore(false);
      },
    });
  };

  return (
    <>
      <h1>الفواتير</h1>
      {isActiveSubscription && (
        <CurrentPlan subscriptionData={subscriptionData} />
      )}
      {isActiveSubscription && (
        <CurrentPaymentMethod subscriptionData={subscriptionData} />
      )}
      <Invoices
        invoices={invoices}
        invoicesLength={invoicesLength}
        loadMore={loadMore}
        isLoadingMore={isLoadingMore}
      />
    </>
  );
};

export default Billing;
