const lastCheckedValue = {};

export default (error, {validate, ...config}) => (dispatch) => {
  const {data} = config;
  const [field, value] = Object.entries(data)[0];
  // We don't want to check if the value didn't change
  if (lastCheckedValue[field] && lastCheckedValue[field].value === value) {
    if (lastCheckedValue[field].error) {
      throw error;
    } else {
      return undefined;
    }
  }

  return new Promise((resolve, reject) => {
    dispatch({
      types: [
        'ASYNC_VALIDATION_REQUEST',
        'ASYNC_VALIDATION_SUCCESS',
        'ASYNC_VALIDATION_FAILURE',
      ],
      config,
      loadingBar: false,
      callback: (success, response) => {
        lastCheckedValue[field] = {
          value,
        };
        const passed = typeof validate === 'function' && validate(response);
        if (!success || !passed) {
          lastCheckedValue[field].error = error;
          reject(error);
        } else {
          lastCheckedValue[field].error = undefined;
          resolve();
        }
      },
    });
  });
};
