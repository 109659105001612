import ConfirmationModal from 'modules/common/ConfirmationModal';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const ConfirmationUserNamePopup = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    confirmUserName,
    toggleConfirmUserNamePopup,
    buyCertificate,
    total_price,
    loading,
    firstName,
    lastName,
  } = props;
  return (
    <div className="tl-ns tc">
      {confirmUserName && (
        <ConfirmationModal
          toggleConfirmationPopup={toggleConfirmUserNamePopup}
          title={formatMessage({
            id: 'confirm_user_name_title',
          })}
          description={formatMessage({
            id: 'name_printed_on_certificate',
          })}
          firstName={firstName}
          lastName={lastName}
          dispatchAction={buyCertificate}
          confirmeMessage={formatMessage({
            id: 'yes_name_is_correct',
          })}
          goToEditProfilePageMessage={formatMessage({
            id: 'want_to_change_my_name',
          })}
          isCertificateConfirmation
        />
      )}
      <button
        aria-label="Confirm username popup"
        type="submit"
        className={`${
          loading
            ? 'bg-light-silver'
            : 'bg-blue bg-animate hover-bg-dark-blue pointer'
        } b pv2 ph4 white br2 button-reset bn`}
        onClick={toggleConfirmUserNamePopup}
      >
        <FormattedMessage
          id={total_price ? 'pay_now' : 'take_free_certificate'}
        />
      </button>
    </div>
  );
};

ConfirmationUserNamePopup.propTypes = {
  confirmUserName: PropTypes.bool,
  toggleConfirmUserNamePopup: PropTypes.func,
  buyCertificate: PropTypes.func.isRequired,
  total_price: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default ConfirmationUserNamePopup;
