import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import TracksLibraryBox from 'modules/common/TracksLibraryBox';
import AvailableCertificate from './AvailableCertificate';
import PaidCertificate from './PaidCertificate';

const CertificatesWrapper = ({certificates, paid, title}) => {
  const Certificate = paid ? PaidCertificate : AvailableCertificate;
  return (
    <div>
      {certificates.length === 0 ? (
        <TracksLibraryBox
          certificate
          title={title}
          message="no_certificates_available"
        />
      ) : (
        <div className="mt5">
          <div className="tr-ns tc bb b--light-gray pb3 f4">
            <h4 className="lh-copy">
              <FormattedMessage id={title} />
            </h4>
          </div>
          <div className="pv3 tr cf">
            {certificates.map((certificate) => (
              <Certificate
                key={certificate.id}
                {...certificate}
                className="fr"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CertificatesWrapper.propTypes = {
  certificates: PropTypes.array.isRequired,
  paid: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default CertificatesWrapper;
