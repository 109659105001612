import asyncValidator from 'core/libs/asyncValidator';
import compose from 'core/libs/helpers/compose.js';
import {spinnerWhileLoading} from 'core/libs/hoc';
import disposableEmail from 'data/disposable-email.json';
import {Field, withFormik} from 'formik';
import FileInput from 'modules/common/FileInput';
import FormInputField from 'modules/common/FormInputField';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React, {lazy, Suspense} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import * as Yup from 'yup';
import editEmail from '../../actions/edit-email';
import {updateUserProfile} from '../../actions/profile';
import userFields from '../userFields';
import CountryField from './CountryField';
import {formInput} from './styles.scss';

const Phone = lazy(() => import('modules/common/Phone'));

const asyncValidate = (field, dispatch, formatMessage, currentProfileData) => {
  const fields = {
    email: (value) => {
      if (value === currentProfileData.email) {
        return undefined;
      }
      return dispatch(
        asyncValidator(formatMessage({id: 'email_taken'}), {
          url: `check-email/`,
          method: 'POST',
          data: {
            email: value,
          },
          validate: ({data}) => data && !data.exist,
        }),
      );
    },
    slug: (value) => {
      if (value === currentProfileData.slug) {
        return undefined;
      }
      return dispatch(
        asyncValidator(formatMessage({id: 'slug_taken'}), {
          url: `users/validate_slug/`,
          method: 'POST',
          data: {
            slug: value,
          },
          validate: ({data}) => data && !data.exist,
        }),
      );
    },
  };

  return fields[field];
};

const EditProfileForm = ({
  dispatch,
  intl: {formatMessage},
  handleSubmit,
  user,
  loginProvider,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="mt4-ns mt3">
      <div className="flex flex-row-ns flex-column pv3 bb b--light-gray">
        <Field
          name="photo"
          component={FileInput}
          type="file"
          user={user.data}
          autoComplete="photo"
          label={formatMessage({id: 'upload_new_picture'})}
          placeholder={formatMessage({id: 'upload_new_picture'})}
          className={`${formInput} input-reset mt3 w-100 tr`}
        />
      </div>
    </div>
    {Object.values(userFields).map((params) => {
      const {
        name,
        type,
        label,
        placeholder,
        disabled,
        style,
        autoComplete = 'off',
      } = params;
      switch (type) {
        case 'country':
          return (
            <CountryField
              key={name}
              name={name}
              label={formatMessage({id: label})}
              placeholder={formatMessage({id: placeholder || label})}
            />
          );
        case 'tel':
          return (
            <Suspense fallback={<LoadingIndicator />}>
              <Phone
                key={name}
                className={formInput}
                name={name}
                label={formatMessage({id: label})}
                placeholder={formatMessage({id: placeholder || label})}
              />
            </Suspense>
          );
        case 'email':
          return (
            <Field
              key={name}
              name={name}
              autoComplete={autoComplete}
              className={`${formInput} input-reset mt3 w-100 tr`}
              component={FormInputField}
              style={style}
              disabled={loginProvider === 'futurex'}
              label={formatMessage({id: label})}
              placeholder={formatMessage({id: placeholder || label})}
              type={type}
              validate={asyncValidate(name, dispatch, formatMessage, user.data)}
            />
          );
        default:
          return (
            <Field
              key={name}
              name={name}
              autoComplete={autoComplete}
              className={`${formInput} input-reset mt3 w-100 tr`}
              component={FormInputField}
              style={style}
              disabled={disabled}
              label={formatMessage({id: label})}
              placeholder={formatMessage({id: placeholder || label})}
              type={type}
              validate={asyncValidate(name, dispatch, formatMessage, user.data)}
            />
          );
      }
    })}

    <div className="pt4 tl">
      <button
        aria-label="Save changes"
        type="submit"
        className="w-auto-ns w-100 pv3 ph4 f6 bg-green-light lh-solid bn br2 white b bg-animate hover-bg-green pointer"
      >
        {formatMessage({
          id: 'save_changes',
        })}
      </button>
    </div>
  </form>
);

EditProfileForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  loginProvider: PropTypes.string,
};

const mapStateToProps = ({user}) => ({
  user: user.profile,
  loginProvider: user.loginProvider.provider,
});

const enhance = compose(
  injectIntl,
  connect(mapStateToProps),
  spinnerWhileLoading(({user}) => !user),
  withFormik({
    mapPropsToValues: ({user}) => {
      const profile = user.data;
      return {
        first_name: profile.first_name || '',
        last_name: profile.last_name || '',
        slug: profile.slug || '',
        email: profile.email || '',
        phone: profile.phone || '',
        nationality: profile.nationality || '',
        about: profile.about || '',
        GITHUB: profile.GITHUB || '',
        LINKEDIN: profile.LINKEDIN || '',
        TWITTER: profile.TWITTER || '',
        FACEBOOK: profile.FACEBOOK || '',
        url: profile.url || '',
        photo: profile.photo || '',
        avatar_url: profile.avatar_url || '',
      };
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        first_name: Yup.string().required(
          formatMessage({id: 'enter_first_name'}),
        ),
        last_name: Yup.string().required(
          formatMessage({id: 'enter_last_name'}),
        ),
        email: Yup.string()
          .email(formatMessage({id: 'error_email_invalid'}))
          .required(formatMessage({id: 'error_email'}))
          .test(
            'disposableEmail',
            formatMessage({id: 'error_email_disposable'}),
            (value) => {
              const emailDomain =
                value && value.substring(value.lastIndexOf('@') + 1);
              return !disposableEmail.includes(emailDomain);
            },
          ),
      }),
    asyncValidate,
    asyncChangeFields: ['email', 'slug'],
    handleSubmit: (values, {props}) => {
      if (values.email !== props.user.data.email) {
        props.dispatch(
          editEmail(values.email, () => {
            props.dispatch(updateUserProfile(values, {hasNotif: false}));
          }),
        );
      } else {
        props.dispatch(updateUserProfile(values));
      }
    },
  }),
);

export default enhance(EditProfileForm);
