import cx from 'classnames';
import countriesName from 'data/countryName.json';
import {Field} from 'formik';
import FieldWrapperWithLabel from 'modules/common/FieldWrapperWithLabel';
import ArrowDown from 'modules/common/Svg/ArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import {bgCustomWhite, customSelect, formDropDown} from './styles.scss';

const CountryField = ({label, name, placeholder}) => {
  return (
    <FieldWrapperWithLabel htmlFor={name} label={label}>
      <div
        className={cx(
          'mt3 relative w-100 ba br2 b--transparent',
          bgCustomWhite,
        )}
      >
        <Field
          component="select"
          name={name}
          className={cx(
            'relative z-1 pr3 pl4 w-100 bg-transparent f6 b--transparent',
            customSelect,
            formDropDown,
          )}
        >
          <option value="">{placeholder}</option>

          {countriesName.map((countryOption) => (
            <option value={countryOption} key={countryOption}>
              {countryOption}
            </option>
          ))}
        </Field>
        <ArrowDown color="#666" className="absolute h-100 top-0 left-0 ml2" />
      </div>
    </FieldWrapperWithLabel>
  );
};

CountryField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CountryField;
