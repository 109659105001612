import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedMessage} from 'react-intl';
import divider from 'theme/assets/images/divider.svg';
import logoBlack from 'theme/assets/images/logoblack.svg';
import medal from 'theme/assets/images/medal.svg';
import LowMedal from 'theme/assets/images/low-medal.png';
import {CertificateStyle, certificatePreview, rotate335} from './styles.scss';

const CertificatePreview = ({name, trackName}) => {
  const today = new Date();
  return (
    <div className="w-100 tc relative">
      <div className="absolute center-absolute">
        <div className={`f-5 black-10 ${rotate335}`}>
          <FormattedMessage id="specimen" />
        </div>
      </div>
      <div className="pa3 bg-white">
        <div
          className={`ba b--dashed br3 b--moon-gray tr ${CertificateStyle} `}
          style={{
            backgroundImage: `url(${LowMedal})`,
          }}
        >
          <div className="flex items-start justify-between flex-row-ns flex-column pt3 ph4-ns ph2">
            <div className="ph1 pv3">
              <img src={logoBlack} alt="barmej logo" />
              <div className="pv3">
                <h2 className="b f5 mb1">{name}</h2>
                <h4 className="normal mb2">
                  <FormattedMessage id="certificate_finished" />
                </h4>
                <img
                  className="mb2"
                  width="100%"
                  height="2px"
                  src={divider}
                  alt="divider icon"
                />
                <h2 className="b f5 mb1">{trackName}</h2>
                <img
                  width="100%"
                  height="2px"
                  src={divider}
                  alt="divider icon"
                />
                <h4 className="normal f5 mb1">
                  <FormattedMessage id="certificate_date_finished" />
                </h4>
                <h5 className="b f5">
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    value={today}
                  />
                </h5>
              </div>
            </div>
            <div
              className={`pa4 tc self-center self-start-m self-start-ns ${certificatePreview}`}
              style={{
                backgroundImage: `url(${medal})`,
              }}
            >
              <h4 className="mb1 f5">
                <FormattedMessage id="certificate_certificate" />
              </h4>
              <h4 className="mt1 f5">
                <FormattedMessage id="certificate_track_finished" />
              </h4>
            </div>
          </div>
          <div className="pb2 ph2 cf">
            <p className="normal f7 fl">
              <FormattedMessage id="certificate_registered" />{' '}
              <FormattedDate value={today} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

CertificatePreview.propTypes = {
  name: PropTypes.string.isRequired,
  trackName: PropTypes.string.isRequired,
};

export default CertificatePreview;
