import React from 'react';
// import PropTypes from 'prop-types';
import checkedV2 from 'theme/assets/images/checked-v2.svg';
import {FormattedMessage} from 'react-intl';

const CertificateSkills = () => {
  return (
    <div className="pv4">
      <h2 className="mb2">
        <FormattedMessage id="certificate_skills" />
      </h2>
      <ul className="list pa0 ma0">
        {[1, 2, 3, 4].map((skill) => (
          <li
            key={skill}
            className="green flex items-center flex-row pv2 f4-ns f5"
          >
            <img
              className="ml2"
              height="16px"
              src={checkedV2}
              alt="checked icon"
            />{' '}
            <FormattedMessage id={`certificate_skill_${skill}`} />
          </li>
        ))}
      </ul>
    </div>
  );
};

CertificateSkills.propTypes = {};

export default CertificateSkills;
