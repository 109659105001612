import notification from 'core/libs/helpers/notification';

const updateIsSubscribed = (is_subscribed) => {
  return {
    types: [
      'UPDATE_USER_PROFILE_REQUEST',
      'UPDATE_USER_PROFILE_SUCCESS',
      'UPDATE_USER_PROFILE_FAILURE',
    ],
    config: {
      url: 'users/current/',
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      data: {
        unsubscribed_from_news_letter: !is_subscribed,
      },
    },
    callback: (success) => {
      if (success) {
        Promise.resolve();
      } else {
        Promise.reject();
      }
    },
  };
};

const updateIsProfilePublic = (userId, is_profile_public) => {
  return {
    types: [
      'UPDATE_PRIVACY_REQUEST',
      'UPDATE_PRIVACY_SUCCESS',
      'UPDATE_PRIVACY_FAILURE',
    ],
    config: {
      url: `privacies/${userId}/`,
      method: 'PATCH',
      data: {
        is_profile_public,
      },
    },
    callback: (success) => {
      if (success) {
        Promise.resolve();
      } else {
        Promise.reject();
      }
    },
  };
};

export const updatePreferences = ({is_profile_public, is_subscribed}) => (
  dispatch,
  getState,
) => {
  const updates = [
    updateIsProfilePublic(getState().user.profile.data.id, is_profile_public),
    updateIsSubscribed(is_subscribed),
  ];
  Promise.all(updates.map(dispatch))
    .then(() => {
      notification({
        id: 'success_update_profile',
        type: 'success',
      });
    })
    .catch(() => {
      notification({message: 'There was an error querying the api'});
    });
};
