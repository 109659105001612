/* eslint-disable react/button-has-type */
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import {Link} from 'gatsby';
import ConfirmationModal from 'modules/common/ConfirmationModal';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, withHandlers, withStateHandlers} from 'recompose';
import {deleteAccount} from '../actions/delete-account';
import EditProfileForm from '../components/EditProfileForm/index';

const {PARTNER} = process.env;

const EditProfileContainer = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    initialValues,
    confirmPopup,
    toggleConfirmPopup,
    dispatchDeleteAccount,
  } = props;
  return (
    <div className="pv2 ph3">
      <Helmet
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_edit_profile'
              : 'partner_helmet_edit_profile',
        })}
      />
      <div className="flex-ns justify-between items-center mb3">
        <h3 className="flex1 f3 fw6">
          <FormattedMessage id="edit_account" />
        </h3>
        {initialValues.slug && (
          <Link
            to={`/user/${initialValues.slug}`}
            className="link dim dib mt3 mt0-ns mr2-ns ph2 ph4-ns pv2 bg-near-white ba b--black-10 br2 f6 black-80 lh-copy"
          >
            <FormattedMessage id="view_profile" />
          </Link>
        )}
      </div>
      {initialValues.id && <EditProfileForm initialValues={initialValues} />}
      {/* {confirmPopup && (
        <ConfirmationModal
          toggleConfirmationPopup={toggleConfirmPopup}
          title={formatMessage({
            id: 'delete_account_confirm_title',
          })}
          description={formatMessage({
            id: 'delete_account_confirm_text',
          })}
          dispatchAction={dispatchDeleteAccount}
          confirmeMessage={formatMessage({
            id: 'delete_account_yes',
          })}
          cancelMessage={formatMessage({
            id: 'delete_account_no',
          })}
        />
      )} */}
      {/* <button
        aria-label="Delete account"
        onClick={toggleConfirmPopup}
        className="mt3 link pa0 dim f7 bw0 bg-transparent pointer dark-red b"
      >
        <FormattedMessage id="delete_account" />
      </button> */}
    </div>
  );
};

EditProfileContainer.propTypes = {
  initialValues: PropTypes.object,
  confirmPopup: PropTypes.bool,
  toggleConfirmPopup: PropTypes.func,
  dispatchDeleteAccount: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initialValues = {};
  Object.entries(state.user.profile.data).forEach(([key, value]) => {
    initialValues[key] = value !== 'null' ? value : '';
  });
  return {
    initialValues,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStateHandlers(
    {
      confirmPopup: false,
    },
    {
      toggleConfirmPopup: ({confirmPopup}) => () => {
        return {
          confirmPopup: !confirmPopup,
        };
      },
    },
  ),
  withHandlers({
    dispatchDeleteAccount: ({dispatch}) => () => {
      dispatch(deleteAccount());
    },
  }),
  spinnerWhileLoading(({initialValues}) => !initialValues),
);

export default enhance(EditProfileContainer);
