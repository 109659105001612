import notification from 'core/libs/helpers/notification';

export const changePassword = (values) => (dispatch) => {
  const formData = ['current_password', 'password', 'repeat_password'].reduce(
    (data, field) => {
      data.append(field, values[field]);
      return data;
    },
    new FormData(),
  );
  dispatch({
    types: [
      'CHANGE_PASSWORD_REQUEST',
      'CHANGE_PASSWORD_SUCCESS',
      'CHANGE_PASSWORD_FAILURE',
    ],
    config: {
      url: 'users/current/set_password/',
      method: 'PATCH',
      data: formData,
    },
    callback: (success, result) => {
      if (success) {
        notification({
          id: 'password_updated',
          type: 'success',
        });
      } else if (result.response.status >= 400) {
        const notificationMessage = Object.values(
          result.response.data,
        ).toString();
        notification({message: notificationMessage});
      } else {
        dispatch(
          notification({message: 'There was an error querying the api'}),
        );
      }
    },
  });
};

export default changePassword;
