const getInvitations = () => {
  return {
    types: [
      'GET_INVITATIONS_REQUEST',
      'GET_INVITATIONS_SUCCESS',
      'GET_INVITATIONS_FAILURE',
    ],
    config: {
      url: 'referral/invitation/',
    },
  };
};

export default getInvitations;
