const MAX_RETRIES = 5;

export const getInvoiceID = (order_no, retry = MAX_RETRIES) => (dispatch) => {
  dispatch({
    types: [
      'GET_INVOICE_ID_REQUEST',
      'GET_INVOICE_ID_SUCCESS',
      'GET_INVOICE_ID_FAILURE',
    ],
    config: {
      method: 'GET',
      url: `billing/invoices-v2/?order_no=${order_no}`,
    },
    payload: {order_no, retry},
    shouldCallAPI: (state) =>
      order_no && retry && state.user.payment.invoiceID.status !== 'fetching',
    callback: (passed, result) => {
      if (passed && result.data.count === 0) {
        setTimeout(() => {
          dispatch(getInvoiceID(order_no, retry - 1));
        }, 1000);
      }
    },
  });
};

export const getPaymentURL = (id, data) => {
  return {
    types: [
      'GET_PAYMENT_URL_REQUEST',
      'GET_PAYMENT_URL_SUCCESS',
      'GET_PAYMENT_URL_FAILURE',
    ],
    config: {
      method: 'POST',
      url: `billing/invoices-v2/${id}/pay_tap/`,
      data,
    },
    payload: {id},
    shouldCallAPI: (state) =>
      id &&
      state.user.payment.paymentURL.status !== 'fetching' &&
      state.user.payment.paymentURL.status !== 'fetched',
  };
};

export const resetPaymentData = () => ({type: 'RESET_PAYMENT_DATA'});
