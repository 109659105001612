import {Router} from '@reach/router';
import redirectIfAnonymous from 'core/libs/hoc/redirectIfAnonymous';
import PropTypes from 'prop-types';
import React from 'react';
import AccountSettings from './containers/AccountSettings';
import AfterPayment from './containers/AfterPaymentContainer';
//import BootcampsBilling from './containers/BootcampsBilling';
import Billing from './containers/Billing';
import Certificate from './containers/Certificate';
import Certificates from './containers/Certificates';
import ChangePassword from './containers/ChangePasswordContainer';
import EditProfile from './containers/EditProfileContainer';
import ReferralInvite from './containers/ReferralInvite';
import ReferralsListContainer from './containers/ReferralsListContainer';
import User from './containers/User';

const UserRouter = (props) => (
  <User pathname={props.location.pathname}>
    <Router>
      <Certificates path="/account/certificates" />
      <Certificate path="/account/certificates/:trackId" />
      <EditProfile path="/account/edit" />
      <ChangePassword path="/account/password" />
      <AfterPayment path="/account/after-payment" />
      <ReferralsListContainer path="/account/referrals" />
      <ReferralInvite path="/account/referrals/invite" />
      <Billing path="/account/billing" />
      <AccountSettings path="/account/settings" />
    </Router>
  </User>
);

UserRouter.propTypes = {
  location: PropTypes.object.isRequired,
};

export default redirectIfAnonymous(UserRouter);
