import notification from 'core/libs/helpers/notification';

export default (email, callback) => (dispatch) => {
  const {PARTNER} = process.env;
  dispatch({
    types: [
      'CHANGE_EMAIL_REQUEST',
      'CHANGE_EMAIL_SUCCESS',
      'CHANGE_EMAIL_FAILURE',
    ],
    config: {
      url: `profile/email-edit/`,
      method: 'POST',
      data: {
        email_to: email,
        partner: PARTNER,
      },
    },
    callback: (success, result) => {
      if (success) {
        const message = result && result.data && result.data.message;
        notification({
          message,
          type: 'success',
        });
        callback();
      } else if (result.response && result.response.status >= 400) {
        const errors = Object.values(result.response.data);
        notification({message: errors && errors[0] && errors[0].toString()});
      }
    },
  });
};
