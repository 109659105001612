import FacebookIcon from 'modules/common/Svg/FacebookIcon';
import LinkedIn from 'modules/common/Svg/LinkedIn';
import TwitterIcon from 'modules/common/Svg/TwitterIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {sendAnalytics} from './actions';
import {facebook, linkedIn, socialBox, twitter} from './styles.scss';

const SocialShareLink = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {url, isAnalytics, dispatch} = props;
  return (
    <>
      <a
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&summary=${formatMessage(
          {
            id: 'refer_on_linkedin_text',
          },
        )}`}
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          isAnalytics ? dispatch(sendAnalytics('linkedin')) : null;
        }}
        target="_blank"
        className={`${socialBox} ${linkedIn} br1`}
      >
        <LinkedIn width="16" height="16" />
      </a>
      <a
        rel="noopener noreferrer"
        href={`https://twitter.com/share?text=${formatMessage({
          id: 'refer_on_twitter_text',
        })}&url=${url}`}
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          isAnalytics ? dispatch(sendAnalytics('twitter')) : null;
        }}
        target="_blank"
        className={`${socialBox} ${twitter} br1 mr2`}
      >
        <TwitterIcon width="16" height="16" />
      </a>
      <a
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}&title=${formatMessage(
          {id: 'helmet_go_back'},
        )}&description=${formatMessage({
          id: 'refer_on_facebook_text',
        })}&caption=https://barmej.com&picture=https://www.barmej.com/barmej_1200x630.png`}
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          isAnalytics ? dispatch(sendAnalytics('facebook')) : null;
        }}
        target="_blank"
        className={`${socialBox} ${facebook} br1 mr2`}
      >
        <FacebookIcon width="16" height="16" />
      </a>
    </>
  );
};

SocialShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  isAnalytics: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default SocialShareLink;
