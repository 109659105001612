import {fetchEntityIfNeeded} from 'core/api/actions.entity';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import {Field, withFormik} from 'formik';
import CheckBox from 'modules/common/CheckBox';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';
import {updatePreferences} from '../actions/preferences';

const {PARTNER} = process.env;

const AccountSettings = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {handleSubmit} = props;
  return (
    <div className="pv2 ph3">
      <Helmet
        title={`${formatMessage({
          id: 'settings',
        })} | ${formatMessage({
          id: PARTNER === 'BARMEJ' ? 'helmet_barmej' : 'partner_helmet',
        })}`}
      />
      <div className="flex-ns justify-between items-center mb3">
        <h3 className="f3 fw6">
          <FormattedMessage id="settings" />
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <Field
          name="is_subscribed"
          component={CheckBox}
          label={formatMessage({id: 'receive_emails_notifications'})}
        />
        <Field
          component={CheckBox}
          name="is_profile_public"
          label={formatMessage({id: 'public_profile'})}
        />
        <div className="pt4 tl">
          <button
            aria-label="Save changes"
            type="submit"
            className="w-auto-ns w-100 pv3 ph4 f6 bg-green-light bg-animate hover-bg-green lh-solid bn br2 white b pointer"
          >
            <FormattedMessage id="save_changes" />
          </button>
        </div>
      </form>
    </div>
  );
};

AccountSettings.propTypes = {
  handleSubmit: PropTypes.func,
};

const enhance = compose(
  connect(({user, entities}) => {
    const profile = user.profile ? user.profile.data : {};
    const privacy =
      (entities.privacies && entities.privacies[profile.id]) || {};
    const privacyIsLoading = privacy.status !== 'fetched';
    const profileIsLoading = !user.profile.fetched;
    return {
      isLoading: privacyIsLoading || profileIsLoading,
      userId: profile.id,
      init: {
        is_profile_public: privacy.is_profile_public,
        is_subscribed: !profile.unsubscribed_from_news_letter,
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      const {dispatch, userId} = this.props;
      if (userId) {
        dispatch(fetchEntityIfNeeded('privacies', userId, true));
      }
    },
    componentDidUpdate(prevProps) {
      const {dispatch, userId} = this.props;
      if (prevProps.userId !== userId) {
        dispatch(fetchEntityIfNeeded('privacies', userId, true));
      }
    },
  }),
  spinnerWhileLoading(({isLoading}) => isLoading),
  withFormik({
    mapPropsToValues: (props) => {
      return props.init;
    },
    handleSubmit(values, {props}) {
      props.dispatch(updatePreferences(values));
    },
  }),
);

export default enhance(AccountSettings);
