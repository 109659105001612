import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {MenuUser} from '../../components';
import {minHeight} from './styles.scss';

const RE = /(\/certificates\/?|\/after-payment\/?|\/invite\/?)/;

class User extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.node.isRequired,
    pathname: PropTypes.string.isRequired,
  };

  render() {
    return !RE.test(this.props.pathname) ? (
      <div className={`ph5-ns ph3 pt5-ns mt4 ${minHeight}`}>
        <div className="cf">
          <div className="fr-l w-25-l pl4-l mb4">
            <MenuUser />
          </div>
          <div className="fl-l w-70-l pa4 ba b--light-gray br2">
            {this.props.children}
          </div>
        </div>
      </div>
    ) : (
      <div>{this.props.children}</div>
    );
  }
}

export default User;
