import notification from 'core/libs/helpers/notification';
import log from 'core/libs/logger';
import {navigate, replace} from 'gatsby';
import {signOut} from 'modules/auth/actions/sign-out';
import {ENTITIES_URL} from './reducer.js';

export const fetchEntityIfNeeded = (
  category,
  selected,
  force,
  showLoadingBar = true,
) => (dispatch) => {
  if (selected === undefined || selected === 'undefined') {
    log('Fetching an undefined slug/id ?', {
      category,
      selected,
      force,
      showLoadingBar,
      currentLocation: typeof window !== 'undefined' && window.location.href,
    });
  }
  dispatch({
    types: ['GET_ENTITY_REQUEST', 'GET_ENTITY_SUCCESS', 'GET_ENTITY_FAILURE'],
    config: {
      url: `${ENTITIES_URL[category]}${selected}/`,
    },
    loadingBar: showLoadingBar,
    shouldCallAPI: ({entities}) => {
      if (force) return true;
      if (!entities[category] || !entities[category][selected]) {
        return true;
      }
      if (
        entities[category][selected].status !== 'fetched' &&
        entities[category][selected].status !== 'fetching'
      ) {
        return true;
      }
      return false;
    },
    payload: {category, selected},
    callback: (passed, data) => {
      if (!passed) {
        if (data.response) {
          if (data.response.status === 403) {
            if (
              data.response.data &&
              data.response.data.message ===
                'Invalid authentication credentials'
            ) {
              notification({id: 'session_expired'});
              dispatch(signOut());
              navigate('/auth/login');
            } else replace('/403');
          }
          if (category === 'tracks' && data.response.status === 404) {
            replace('/404');
          }
        }
      }
    },
  });
};

export default fetchEntityIfNeeded;
