/* eslint-disable react-hooks/exhaustive-deps */
import {Link} from 'gatsby';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';

const ReferralsList = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {invitations} = props;
  const [listItems, setListItems] = useState(
    invitations && invitations.data && invitations.data.slice(0, 5),
  );

  const [isFetching, setIsFetching] = useState(false);

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    setTimeout(() => {
      setListItems((prevState) => [
        ...prevState,
        ...(invitations &&
          invitations.data &&
          invitations.data.slice(prevState.length, prevState.length + 5)),
      ]);
      setIsFetching(false);
    }, 2000);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching, fetchMoreListItems]);

  return (
    <div className="pv2 ph3-ns">
      <Helmet
        title={`${formatMessage({
          id: 'referrals',
        })} | ${formatMessage({
          id: 'helmet_barmej',
        })}`}
      />
      <div className="flex-ns justify-between items-center mb3">
        <h3 className="f3 fw6">
          <FormattedMessage id="referrals_alt" />
        </h3>
        <Link
          to="/account/referrals/invite"
          className="link dim dib mt3 mt0-ns ph2 ph4-ns pv2 bg-near-white ba b--black-10 br2 f6 black-80 lh-copy"
        >
          <FormattedMessage id="send_invitation" />
        </Link>
      </div>
      {listItems.length > 0 ? (
        <>
          <div className="f6 ba b--black-10 ph2 ph0-ns">
            <div className="dt-ns dn pv4 bb b--black-10 w-100 b bg-near-white">
              <div className="dtc w-30 ph3 ph2-m">
                <FormattedMessage id="email" />
              </div>
              <div className="dtc w-30 w-35-m ph3 ph2-m">
                <FormattedMessage id="status" />
              </div>
              <div className="dtc w-25 ph3 ph2-m">
                <FormattedMessage id="invitation_date" />
              </div>
              <div className="dtc w-15-l w-10-m ph3 ph2-m">
                <FormattedMessage id="points" />
              </div>
            </div>
            {listItems &&
              listItems.map(
                ({id, invitee_email, invite_status, accepted_at, sent_at}) => {
                  return (
                    <div
                      className="dt-ns dt--fixed w-100 pv4 bb b--black-05"
                      key={id}
                    >
                      <div className="flex items-center dtc-ns w-30-ns ph3-ns ph2-m">
                        <div className="dn-ns w-50 b f7">
                          <FormattedMessage id="email" />
                        </div>
                        <div
                          className="truncate mw-50 mw-100-ns"
                          data-cy={invitee_email}
                        >
                          {invitee_email}
                        </div>
                      </div>
                      <div className="flex items-center dtc-ns w-30-ns w-35-m pt2 pv0-ns ph3-ns ph2-m">
                        <div className="dn-ns w-50 b f7">
                          <FormattedMessage id="status" />
                        </div>
                        <div className="f7">
                          <span
                            className={`dib pv1 ph3 ph1-m br2 ${
                              accepted_at
                                ? 'bg-green-light white'
                                : 'bg-near-white black-80'
                            }`}
                          >
                            {invite_status}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center dtc-ns w-25-ns pt2 pv0-ns ph3-ns ph2-m">
                        <div className="dn-ns w-50 b f7">
                          <FormattedMessage id="invitation_date" />
                        </div>
                        <div>
                          <FormattedDate value={accepted_at || sent_at} />
                        </div>
                      </div>
                      <div className="flex items-center dtc-ns w-15-ns w-10-m pt2 pv0-ns ph3-ns ph2-m">
                        <div className="dn-ns w-50 b f7">
                          <FormattedMessage id="points" />
                        </div>
                        <div>{accepted_at ? 110 : 10}</div>
                      </div>
                    </div>
                  );
                },
              )}
            {isFetching && invitations.data.length !== listItems.length && (
              <LoadingIndicator fullScreen={false} className="pv4" />
            )}
          </div>
        </>
      ) : (
        <p className="pt3 black-80 f5">
          <FormattedMessage id="there_is_no_referrals_now" />
        </p>
      )}
    </div>
  );
};

ReferralsList.propTypes = {
  invitations: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
};

export default ReferralsList;
