import styled from 'styled-components';

export const Icon = styled.div`
  cursor: pointer;
`;
export const TooltipBox = styled.div`
  position: absolute;
  bottom: -3.2rem;
  left: -0.2rem;
  color: black;
  display: ${({show}) => (show ? 'block' : 'none')};
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 9.3rem;
  padding: 1rem;
  box-shadow: 0px 8px 32px rgba(79, 89, 106, 0.15);
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: '';
    display: ${({show}) => (show ? 'block' : 'none')};
    width: 0;
    height: 0;
    left: 1rem;
    top: -5px;
    position: absolute;
    border: 5px solid #fff;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 0.87rem;
      color: #ff2768;
    }
  }
`;
