import React from 'react';

export default ({fill, className, onClick}) => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.491264 0.198901C0.186897 0.479854 0.167917 0.954348 0.448871 1.25871L5.97931 7.25L0.448871 13.2413C0.167917 13.5457 0.186897 14.0202 0.491264 14.3011C0.79563 14.5821 1.27013 14.5631 1.55108 14.2587L7.5511 7.75871C7.8163 7.47142 7.8163 7.02859 7.5511 6.74129L1.55108 0.241294C1.27013 -0.0630719 0.79563 -0.0820516 0.491264 0.198901Z"
      fill={fill}
    />
  </svg>
);
