import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import CertificatePreview from '../certificate/CertificatePreview';
import CertificationBox from './CertificationBox';
import ModalButton from './ModalButton';
import {blueText} from './styles.scss';

const AvailableCertificate = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {className = '', first_name, id, last_name, name, icon} = props;
  return (
    <CertificationBox
      id={id}
      title={formatMessage({
        id: 'track_certificate',
      })}
      trackTitle={name}
      className={className}
      trackIcon={icon}
    >
      <ModalButton
        className={`${blueText} underline b bg-transparent bn outline-0 pointer`}
        modalContent={
          <CertificatePreview
            name={`${first_name} ${last_name}`}
            trackName={name}
          />
        }
      >
        <FormattedMessage id="preview_certificate" />
      </ModalButton>
    </CertificationBox>
  );
};

AvailableCertificate.propTypes = {
  className: PropTypes.string,
  first_name: PropTypes.string,
  id: PropTypes.number.isRequired,
  last_name: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

const enhance = connect(({user}) => ({
  first_name: user.profile.data.first_name,
  last_name: user.profile.data.last_name,
}));

export default enhance(AvailableCertificate);
