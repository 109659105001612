import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  margin: auto;
`;

export const Thead = styled.thead`
  thead {
    color: #50575f;
  }
`;

export const Tbody = styled.tbody`
  color: black;
  tr {
    td {
      span {
        font-weight: bold;
      }
    }
  }
`;
