import React, {useState, Fragment} from 'react';
import {BoxContainer, Header, Button} from '../Common/styles';
import {Table, Thead, Tbody} from './styles';
import LeftArrow from '../icons/left-arrow.js';
import RightArrow from '../icons/right-arrow.js';
import LoadingIndicator from 'modules/common/LoadingIndicator';

const Invoices = ({invoices, invoicesLength, loadMore, isLoadingMore}) => {
  const [activeItem, setActiveItem] = useState(1);
  const pages = Math.ceil(invoicesLength / 4);
  let pagesNumber = [];
  if (pages > 1)
    for (let i = 1; i <= pages; i++) {
      pagesNumber.push(
        <div
          key={i}
          onClick={() => {
            setActiveItem(i);
            loadMore(i - 1);
          }}
          className={`mh2 mv1 pa2 ba b--transparent br3 pointer hover-bg-blue hover-white ${
            activeItem === i && 'bg-blue white'
          }`}
        >
          {i}
        </div>,
      );
    }
  return (
    <BoxContainer>
      <Header>
        <h3>الفواتير</h3>
      </Header>
      {!isLoadingMore ? (
        <>
          <Table className="dn dit-ns">
            <Thead>
              <tr>
                <td>التاريخ</td>
                <td>رقم الفاتورة</td>
                <td>الحالة</td>
                <td>المجموع</td>
              </tr>
            </Thead>
            <Tbody>
              {invoices?.map((invoice) => (
                <tr key={invoice.invoiceId}>
                  <td>
                    {new Date(invoice?.createdAt)?.toLocaleDateString('ar', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </td>
                  <td>{invoice?.invoiceId}</td>
                  <td>{invoice?.status === 'PAID' ? 'تم الدفع' : 'ألغيت'}</td>
                  <td>
                    <span>{invoice?.totalPrice} دولار</span>
                  </td>
                </tr>
              ))}
            </Tbody>
          </Table>
          <div className="dn-ns flex flex-column mt2 mh1">
            {invoices?.map((invoice) => (
              <Fragment key={invoice.invoiceId}>
                <div className="flex justify-between mv3">
                  <div className="flex flex-column gray">
                    <span className="pa3">التاريخ</span>
                    <span className="pa3">رقم الفاتورة</span>
                    <span className="pa3">الحالة</span>
                    <span className="pa3">المجموع</span>
                  </div>
                  <div className="flex flex-column items-end">
                    <span className="pa3 tl">
                      {new Date(invoice?.createdAt)?.toLocaleDateString('ar', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </span>
                    <span className="pa3">{invoice?.invoiceId}</span>
                    <span className="pa3">
                      {invoice?.status === 'PAID' ? 'تم الدفع' : 'ألغيت'}
                    </span>
                    <span className="pa3 fw6">{invoice?.totalPrice} دولار</span>
                  </div>
                </div>
                <div className="center w-90 bb b--light-gray" />
              </Fragment>
            ))}
          </div>
        </>
      ) : (
        <LoadingIndicator className="h5-ns" />
      )}
      {pagesNumber.length > 0 && (
        <div className="flex flex-row-reverse justify-center items-center pa2">
          <LeftArrow
            className="mr3 pointer white"
            fill={activeItem <= 1 ? '#979797' : '#3F464E'}
            onClick={() => {
              if (activeItem > 1) {
                setActiveItem(activeItem - 1);
                loadMore(activeItem - 2);
              }
            }}
          />
          {pagesNumber.map((item) => {
            return item;
          })}
          <RightArrow
            className="ml3 pointer"
            fill={activeItem >= pages ? '#979797' : '#3F464E'}
            onClick={() => {
              if (activeItem < pages) {
                setActiveItem(activeItem + 1);
                loadMore(activeItem);
              }
            }}
          />
        </div>
      )}
    </BoxContainer>
  );
};

export default Invoices;
