import React, {useState, useEffect, useRef} from 'react';
import ReactTooltip from 'react-tooltip';
import {navigate} from '@reach/router';
import CancelModal from '../CancelModal';
import TemporayModal from '../TemporaryModal';
import {Content, FlexColumn, Feature, EndTime, Plan, Price} from './styles';
import {
  BoxContainer,
  Header,
  Button,
  TemporaryButton,
} from '../Common/styles.js';
import subscriptionPlan from 'modules/subscription/content/subscriptionPlan.js';
import CancelTooltip from '../CancelTooltip';
import calendar from '../icons/calendar.svg';
import info from '../icons/info-circle.svg';
import CheckMark from 'modules/subscription/icons/checkMark.svg';
import AddMark from 'modules/subscription/icons/add.svg';
import Crown from 'modules/subscription/icons/subscription_crown.svg';

const CurrentPlan = ({subscriptionData}) => {
  const [plan, setPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTemporaryModal, setIsTemporaryModal] = useState(false);

  const planName = subscriptionData?.subscriptionPlan?.planName;
  const nextStatus = subscriptionData?.nextStatus;
  // Convert expiry date of subscription plan to arabic format
  const planExpiresAt = new Date(
    subscriptionData?.expiresAt,
  )?.toLocaleDateString('ar', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  useEffect(() => {
    if (planName) {
      const plan = subscriptionPlan.find((plan) => plan.planType === planName);
      setPlan(plan);
    }
  }, [planName]);

  return (
    <BoxContainer>
      <Header>
        <h3>اشتراكك الحالي</h3>
        <CancelTooltip setIsModalVisible={setIsModalVisible} />
      </Header>
      {plan && (
        <Content>
          <FlexColumn>
            <Plan>
              <h3>{plan?.title}</h3>
              {plan?.titleIcon ? (
                <div>
                  <img src={Crown} alt="crown-icon" />
                </div>
              ) : null}
              <a data-tip data-for="plan-benefits">
                <img src={info} width="20px" height="20px" alt="info" />
              </a>
              <ReactTooltip
                id="plan-benefits"
                place="left"
                type="dark"
                effect="solid"
              >
                {plan?.benefits?.map((benefit, i) => (
                  <Feature key={i} index={i}>
                    <img src={i === 2 ? AddMark : CheckMark} alt="list icon" />
                    <p>{benefit}</p>
                  </Feature>
                ))}
              </ReactTooltip>
            </Plan>
            <p>{plan?.subtitle}</p>
          </FlexColumn>
          <FlexColumn>
            <Price>
              {plan?.discountedPrice}
              <span>
                {' '}
                دولار /{plan?.planType === 'MONTHLY' ? 'شهريا' : 'سنويا'}
              </span>
            </Price>
            <EndTime>
              <img src={calendar} width="20px" height="20px" alt="calendar" />
              <p>ينتهي في {planExpiresAt}</p>
            </EndTime>
          </FlexColumn>
          <Button
            disabled={nextStatus === 'CANCELED'}
            to={
              nextStatus === 'CANCELED'
                ? '/account/billing'
                : '/subscription/change-subscription-plan'
            }
          >
            تغيير باقة الاشتراك
          </Button>
          {/* <TemporaryButton onClick={() => setIsTemporaryModal(true)}>
            تغيير باقة الاشتراك
          </TemporaryButton> */}
        </Content>
      )}
      {isModalVisible && (
        <CancelModal
          exitPopup={() => setIsModalVisible(false)}
          recurrentPaymentId={subscriptionData?.recurrentPaymentId}
          planExpiresAt={planExpiresAt}
          nextStatus={nextStatus}
        />
      )}
      {/* {isTemporaryModal && (
        <TemporayModal exitPopup={() => setIsTemporaryModal(false)} />
      )} */}
    </BoxContainer>
  );
};

export default CurrentPlan;
