import styled from 'styled-components';

export const Content = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    img {
      height: 40px;
    }
    h3 {
      font-size: 0.87rem;
      margin: 0 2rem;
      @media (max-width: 1200px) {
        margin: 0;
        margin-right: 1.5rem;
      }
      @media (max-width: 500px) {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
    p {
      font-size: 0.87rem;
      color: #50575f;
      @media (max-width: 1200px) {
        margin-right: 1.5rem;
      }
      @media (max-width: 500px) {
        font-size: 0.75rem;
      }
    }
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
