import notification from 'core/libs/helpers/notification';
import {signOut} from 'modules/auth/actions/sign-out';

export const deleteAccount = () => (dispatch, getState) => {
  const userId = getState().user.profile.data.id;
  dispatch({
    types: [
      'DELETE_ACCOUNT_REQUEST',
      'DELETE_ACCOUNT_SUCCESS',
      'DELETE_ACCOUNT_FAILURE',
    ],
    config: {
      url: `users/${userId}/`,
      method: 'DELETE',
    },
    callback: (success, result) => {
      if (success) {
        dispatch(signOut());
      } else if (result.response && result.response.status >= 400) {
        const errorMessage = Object.values(result.response.data).toString();
        notification({message: errorMessage});
      } else {
        dispatch(
          notification({
            message: 'There was an error querying the api',
          }),
        );
      }
    },
  });
};

export default deleteAccount;
