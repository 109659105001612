import React from 'react';
import discountImg from 'theme/assets/images/discount.svg';
import {FormattedMessage} from 'react-intl';
import {customTop} from './styles.scss';

const ReferralIntro = () => (
  <div className={`tc relative ${customTop}`}>
    <img
      src={discountImg}
      height="200px"
      width="200px"
      alt="discount"
      className="mb3"
    />
    <p className="fw4 black lh-copy f4-ns f5 mw6-ns mw8-m mw-none center">
      <FormattedMessage id="referral_note" />
    </p>
  </div>
);

export default ReferralIntro;
