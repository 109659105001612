import {Field, withFormik} from 'formik';
import FormInputField from 'modules/common/FormInputField';
import {changePassword} from 'modules/user/actions/change-password';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import * as Yup from 'yup';
import {formInput} from './styles.scss';

const ChangePassword = ({intl: {formatMessage}, handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="current_password"
      autoComplete="current-password"
      type="password"
      component={FormInputField}
      placeholder={formatMessage({id: 'label_old_password'})}
      label={formatMessage({id: 'label_old_password'})}
      className={`${formInput} input-reset mt3 w-100 tr`}
    />

    <Field
      name="password"
      type="password"
      autoComplete="new-password"
      component={FormInputField}
      placeholder={formatMessage({id: 'label_new_password'})}
      label={formatMessage({id: 'label_new_password'})}
      className={`${formInput} input-reset mt3 w-100 tr`}
    />

    <Field
      name="repeat_password"
      type="password"
      autoComplete="new-password"
      component={FormInputField}
      placeholder={formatMessage({id: 'label_confirm_password'})}
      label={formatMessage({id: 'label_confirm_password'})}
      className={`${formInput} input-reset mt3 w-100 tr`}
    />

    <div className="pt4 tl">
      <button
        aria-label="Save changes"
        type="submit"
        className="w-auto-ns w-100 pv3 ph4 f6 bg-green-light bg-animate hover-bg-green lh-solid bn br2 white b pointer"
      >
        <FormattedMessage id="save_changes" />
      </button>
    </div>
  </form>
);

ChangePassword.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(null, {
    changePassword,
  }),
  injectIntl,
  withFormik({
    mapPropsToValues: () => {
      return {
        current_password: '',
        password: '',
        repeat_password: '',
      };
    },
    handleSubmit: (values, {props}) => {
      props.changePassword(values);
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        current_password: Yup.string()
          .min(8, formatMessage({id: 'password_number_chars'}))
          .required(formatMessage({id: 'field_required'})),
        password: Yup.string()
          .min(8, formatMessage({id: 'password_number_chars'}))
          .required(formatMessage({id: 'field_required'})),
        repeat_password: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            formatMessage({id: 'password_not_match'}),
          )
          .required(formatMessage({id: 'field_required'})),
      }),
  }),
);

export default enhance(ChangePassword);
