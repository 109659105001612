/* eslint-disable react-hooks/exhaustive-deps */
import compose from 'core/libs/helpers/compose.js';
import redirectIfPartner from 'core/libs/hoc/redirectIfPartner';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import ReferralsList from 'modules/common/ReferralFriends/components/ReferralsList';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import getInvitations from '../actions/referrals';

const ReferralsListContainer = ({invitations, dispatch}) => {
  useEffect(() => {
    dispatch(getInvitations());
  }, []);

  if (invitations.status !== 'fetched') return <LoadingIndicator />;

  return <ReferralsList invitations={invitations} />;
};

ReferralsListContainer.propTypes = {
  invitations: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func,
};

const enhance = compose(
  redirectIfPartner,
  connect((state) => {
    return {
      invitations: state.user.referrals.invitationsList,
    };
  }),
);

export default enhance(ReferralsListContainer);
