import React, {useState, useEffect, useRef} from 'react';
import {Icon, TooltipBox} from './styles';
import more from '../icons/more.svg';
import cancel from '../icons/cancel.svg';
const CancelTooltip = ({setIsModalVisible}) => {
  const tootltipRef = useRef();
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  useEffect(() => {
    const handleDropDownToggle = (e) => {
      if (!tootltipRef?.current?.contains(e.target)) {
        setIsShowTooltip(false);
      }
    };
    window.addEventListener('mousedown', handleDropDownToggle);
    return () => {
      window.removeEventListener('mousedown', handleDropDownToggle);
    };
  });
  const handleCancel = () => {
    setIsModalVisible(true);
    setIsShowTooltip(false);
  };
  return (
    <>
      <Icon onClick={() => setIsShowTooltip(true)}>
        <img src={more} alt="click_more" />
      </Icon>

      <TooltipBox ref={tootltipRef} show={isShowTooltip}>
        <div onClick={handleCancel}>
          <img src={cancel} width="20px" />
          <p>إلغاء الاشتراك</p>
        </div>
      </TooltipBox>
    </>
  );
};

export default CancelTooltip;
