import React, {useState} from 'react';
import {navigate} from 'gatsby';
import axios from 'axios';
import Popup from 'modules/common/Popup';
import {useSelector} from 'react-redux';
import {Container, Button} from './styles';
import cancelSub from '../icons/cancel-sub.svg';
import notification from 'core/libs/helpers/notification';
import {BlueLoader} from 'modules/subscription/styles';

const CancelModal = ({
  exitPopup,
  recurrentPaymentId,
  planExpiresAt,
  nextStatus,
}) => {
  const auth = useSelector((state) => state.auth);
  const [submitStatus, setSubmitStatus] = useState('idle');

  const apiKey = auth?.key;

  const handleCancelSubscription = async () => {
    setSubmitStatus('loading');
    try {
      const res = await axios.post(
        `${process.env.GATSBY_API2}api/v1/billing/subscription/${recurrentPaymentId}/cancel-subscription/?apikey=${apiKey}`,
      );
      if (res.status === 200) {
        navigate('/dashboard');
        notification({
          id: 'cancel_subscription',
          type: 'success',
        });
      }
      setSubmitStatus('idle');
    } catch (err) {
      notification({id: 'server_error_try_again'});
      setSubmitStatus('idle');
    }
  };

  return (
    <Popup exitPopup={exitPopup} hideCloseIcon>
      <Container>
        {nextStatus === 'CANCELED' ? (
          <>
            <h3>لقد ألغيت اشتراكك</h3>

            <p>
              سوف تتمكن من الوصول إلى محتوى برمج حتى ينتهي الإشتراك الحالي
              بتاريخ {planExpiresAt} .
            </p>
          </>
        ) : (
          <>
            <img height="148px" width="187px" src={cancelSub} />
            <h3>هل أنت متأكد أنك تريد إلغاء اشتراكك؟</h3>
            <p>
              عند إلغاء الإشتراك، سوف تتمكن من الوصول إلى محتوى برمج حتى ينتهي
              الإشتراك الحالي بتاريخ {planExpiresAt} .لن يتم تجديد اشتراكك بشكل
              تلقائي فور انتهائه.
            </p>
            <div>
              <Button variant="primary" onClick={exitPopup}>
                لا
              </Button>
              <Button
                onClick={handleCancelSubscription}
                disabled={submitStatus === 'loading'}
              >
                {submitStatus === 'loading' ? (
                  <BlueLoader />
                ) : (
                  <span>نعم ,إلغاء</span>
                )}
              </Button>
            </div>
          </>
        )}
      </Container>
    </Popup>
  );
};

export default CancelModal;
