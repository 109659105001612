import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import checkedCircle from 'theme/assets/images/checked-circle.svg';
import CertificationBox from './CertificationBox';

const PaidCertificate = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {className, pdf_link, track_name, icon} = props;
  return (
    <CertificationBox
      title={formatMessage({
        id: 'track_certificate',
      })}
      trackTitle={track_name}
      payed
      filePdf={pdf_link}
      className={className}
      trackIcon={icon}
    >
      <img
        src={checkedCircle}
        alt="paid certification"
        height="33px"
        width="33px"
      />
    </CertificationBox>
  );
};

PaidCertificate.propTypes = {
  className: PropTypes.string,
  track_name: PropTypes.string.isRequired,
  pdf_link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default PaidCertificate;
