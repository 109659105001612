const fields = [
  {
    name: 'email',
    type: 'email',
    label: 'label_email_adress',
    autoComplete: 'email',
  },
  {
    name: 'slug',
    type: 'text',
    label: 'label_slug',
  },
  {
    name: 'first_name',
    type: 'text',
    label: 'label_first_name',
    autoComplete: 'given-name',
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'label_last_name',
    autoComplete: 'family-name',
  },
  {
    name: 'phone',
    type: 'tel',
    label: 'label_phone',
    autoComplete: 'tel',
  },
  {
    name: 'nationality',
    type: 'country',
    label: 'country',
    placeholder: 'select_country_name',
  },
  {
    name: 'about',
    type: 'textarea',
    label: 'label_about',
    placeholder: 'bio_placeholder',
    style: {paddingTop: '1rem'},
  },
  {
    name: 'GITHUB',
    type: 'text',
    label: 'label_github',
    placeholder: 'github_placeholder',
  },
  {
    name: 'LINKEDIN',
    type: 'text',
    label: 'label_linkedin',
    placeholder: 'linkedin_placeholder',
  },
  {
    name: 'TWITTER',
    type: 'text',
    label: 'label_twitter',
    placeholder: 'twitter_placeholder',
  },
  {
    name: 'FACEBOOK',
    type: 'text',
    label: 'label_facebook',
    placeholder: 'facebook_placeholder',
  },
  {
    name: 'url',
    type: 'text',
    label: 'website_url',
    placeholder: 'website_placeholder',
  },
];

export default fields;
