import React from 'react';

export default ({fill, className, onClick}) => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50871 0.198901C7.81308 0.479854 7.83206 0.954348 7.5511 1.25871L2.02068 7.25L7.5511 13.2413C7.83206 13.5457 7.81308 14.0202 7.50871 14.3011C7.20434 14.5821 6.72985 14.5631 6.4489 14.2587L0.448897 7.75871C0.183701 7.47142 0.183701 7.02859 0.448897 6.74129L6.4489 0.241294C6.72985 -0.0630719 7.20434 -0.0820516 7.50871 0.198901Z"
      fill={fill}
    />
  </svg>
);
