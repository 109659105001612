import React from 'react';
import {Content} from './styles';
import {BoxContainer, Header, Button, DisabledButton} from '../Common/styles';
import visaLogo from '../icons/visa-logo.svg';
import mastercardLogo from '../icons/mastercard-logo.svg';
import paypalLogo from '../icons/paypal.png';
const CurrentPaymentMethod = ({subscriptionData}) => {
  const {
    cardName,
    expMonth,
    expYear,
    lastFourDigits,
    name,
  } = subscriptionData?.paymentMethod;
  const LOGO =
    name === 'PAYPAL'
      ? paypalLogo
      : cardName === 'VISA'
      ? visaLogo
      : mastercardLogo;
  const cardArabicName = cardName === 'VISA' ? 'فيزا' : 'ماستر كارد';
  return (
    <BoxContainer>
      <Header>
        <h3>طريقة الدفع</h3>
      </Header>
      <Content>
        <div>
          <img src={LOGO} alt={name} />
          {name === 'PAYPAL' ? (
            <h3>باي بال</h3>
          ) : (
            <div>
              <h3>
                {cardArabicName} تنتهي ب{lastFourDigits}
              </h3>
              <p>
                تاريخ الانتهاء: {expMonth}/{expYear}
              </p>
            </div>
          )}
        </div>
        {/* TODO Changing payment method not working yet */}
        {/*
        <DisabledButton to="/subscription">تحديث معلومات الدفع</DisabledButton> */}
      </Content>
    </BoxContainer>
  );
};

export default CurrentPaymentMethod;
