import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import ChangePassword from '../components/ChangePassword';

const {PARTNER} = process.env;

const ChangePasswordContainer = () => {
  const intl = useIntl();
  const {formatMessage} = intl;
  return (
    <div className="pv2 ph3">
      <Helmet
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_change_password'
              : 'partner_helmet_change_password',
        })}
      />
      <h3 className="f3 fw6 mb4" data-cy="change-password-title">
        <FormattedMessage id="change_password" />
      </h3>
      <ChangePassword />
    </div>
  );
};

export default ChangePasswordContainer;
