export const getInviteStats = () => {
  return {
    types: [
      'GET_REFERRAL_STATS_REQUEST',
      'GET_REFERRAL_STATS_SUCCESS',
      'GET_REFERRAL_STATS_FAILURE',
    ],
    config: {
      url: 'referral/invite-stats/current/',
    },
  };
};

export default getInviteStats;

export const sendInvitations = (users, isReferrals) => {
  const emailList =
    users &&
    users
      .filter(({email}) => email)
      .map((userData) => ({invitee_email: userData.email}));

  if (emailList.length) {
    return {
      types: [
        'CREATE_INVITATIONS_REQUEST',
        'CREATE_INVITATIONS_SUCCESS',
        'CREATE_INVITATIONS_FAILURE',
      ],
      config: {
        method: 'post',
        url: 'referral/invitation/',
        data: emailList,
        headers: {
          'Content-Type': 'application/json',
        },
      },
      callback: (success, result) => {
        if (success) {
          if (isReferrals) {
            if (window && window.mixpanel) {
              window.mixpanel.people.increment(
                'Users invited from Referrals',
                result.data.length,
              );
            }

            if (typeof window !== 'undefined') {
              if (window.dataLayer) {
                window.dataLayer.push({
                  length: result.data.length,
                  event: 'Users invited from Referrals',
                });
              }
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (window && window.mixpanel) {
              window.mixpanel.people.increment(
                'Users invited from Onboarding',
                result.data.length,
              );
            }

            if (typeof window !== 'undefined') {
              if (window.dataLayer) {
                window.dataLayer.push({
                  length: result.data.length,
                  event: 'Users invited from Onboarding',
                });
              }
            }
          }
        } else {
          const getEmailExist =
            result &&
            result.response &&
            result.response.data.filter((emilExist) =>
              Object.values(emilExist).some((emilExists) =>
                emilExists.includes('تم إرسال دعوتك بنجاح'),
              ),
            );
          if (isReferrals) {
            if (window && window.mixpanel) {
              window.mixpanel.people.increment(
                'Users invited from Referrals',
                getEmailExist.length,
              );
            }

            if (typeof window !== 'undefined') {
              if (window.dataLayer) {
                window.dataLayer.push({
                  length: getEmailExist.length,
                  event: 'Users invited from Referrals',
                });
              }
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (window && window.mixpanel) {
              window.mixpanel.people.increment(
                'Users invited from Onboarding',
                getEmailExist.length,
              );
            }

            if (typeof window !== 'undefined') {
              if (window.dataLayer) {
                window.dataLayer.push({
                  length: getEmailExist.length,
                  event: 'Users invited from Onboarding',
                });
              }
            }
          }
        }
      },
    };
  }
  return {type: 'CREATE_INVITATIONS_ERROR_NO_ITEMS'};
};
