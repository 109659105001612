// import parseURLSearch from 'core/libs/helpers/parseURLSearch';
import {fetchEntitiesIfNeeded} from 'core/api/actions.entities';
import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';

const enhance = compose(
  connect(null, {fetchEntities: fetchEntitiesIfNeeded}),
  lifecycle({
    componentDidMount() {
      const {
        fetchEntities,
        location: {search},
      } = this.props;
      if (search) {
        // const params = parseURLSearch(search);

        notification({
          id: 'payment_successful',
          type: 'success',
        });
        setTimeout(() => {
          fetchEntities('certificates', true, true);
          navigate('/account/certificates');
        }, 2500);
      } else {
        navigate('/tracks');
      }
    },
  }),
);

export default enhance(LoadingIndicator);
