import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'gatsby';
import navStructure from 'core/settings/structure';
import ArrowLeft from 'modules/common/Svg/ArrowLeft';
import {linkVerticalPadding, active, arrow} from './styles.scss';

const {PARTNER} = process.env;

const MenuUser = () => {
  const authAndUser = useSelector((state) => state);
  const loginProvider = authAndUser?.user?.loginProvider?.provider;

  return (
    <ul className="list f6 ba b--light-gray br2 tc-m flex-m ma0 pa0">
      {navStructure.accountUserMenu
        .filter(({hideForPartner}) => !hideForPartner || (PARTNER === 'BARMEJ' && loginProvider !== 'futurex'))
        .map(({url, text}, index) => {
          return (
            <li
              key={index}
              className={`flex-grow-1 ${
                index > 0 ? 'bt bt-0-m br-m b--inherit' : ''
              }`}
            >
              <Link
                to={url}
                className={`link dim db ${linkVerticalPadding} ph3 black-40`}
                activeClassName={`${active} color-primary-blue b`}
              >
                {text}
                <div className={`dn fl ${arrow}`}>
                  <ArrowLeft color="#666" />
                </div>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default MenuUser;
