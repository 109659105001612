import styled from 'styled-components';

export const Content = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 0.87rem;
    font-weight: normal;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
  p {
    margin-top: 1rem;
    font-size: 0.87rem;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 482px) {
    p {
      font-size: 0.7rem;
    }
  }
`;

export const Plan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 0.2rem;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    a {
      display: none;
    }
    div {
      img {
        display: block;
      }
    }
  }
  @media (max-width: 482px) {
    h3 {
      font-size: 0.87rem;
    }
  }
`;

export const EndTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  img {
    margin-left: 0.2rem;
  }
`;

export const Feature = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: start;
  max-width: 17rem;

  img {
    margin-left: 0.8rem;
    width: 0.9rem;
    height: 0.9rem;
  }

  p {
    font-size: 0.75rem;
    font-weight: ${({index}) => (index !== 2 ? 'normal' : 'bold')};
    color: #fff;
    line-height: 2rem;
  }
`;

export const Price = styled.h3`
  @media (max-width: 1200px) {
    font-size: 2rem;
    margin: 1.5rem 0 0 0;
    span {
      font-size: 1.2rem;
    }
  }
  @media (max-width: 482px) {
    font-size: 1.8rem;
    span {
      font-size: 1rem;
    }
  }
`;
