import styled from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';

const grey = '#d0d1d3';
const darkGrey = '#777777';
const blue = '#357edd';
const darkBlue = '#00449e';

export const BoxContainer = styled.div`
  width: 100%;
  border: 1px solid ${grey};
  border-radius: 2px;
  margin: 2rem 0;
`;

export const Header = styled.div`
  width: 100%;
  height: 3.4rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(243, 243, 243, 0.5);
  border-right: 8px solid ${grey};
  position: relative;
  @media (max-width: 482px) {
    h3 {
      font-size: 0.87rem;
    }
  }
`;
export const Button = styled(GatsbyLink)`
  background-color: #ffff;
  color: ${({disabled}) => (disabled ? darkGrey : blue)};
  border-radius: 2px;
  border: 2px solid ${({disabled}) => (disabled ? darkGrey : blue)};
  padding: 0.87rem;
  margin: 2rem 0.5rem;
  text-align: center;
  width: 20rem;
  @media (max-width: 1400px) {
    width: 13rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    align-self: center;
  }
  &:hover {
    border: 2px solid ${({disabled}) => (disabled ? darkGrey : darkBlue)};
    color: ${({disabled}) => (disabled ? darkGrey : darkBlue)};
  }
`;

export const TemporaryButton = styled.button`
  background-color: #ffff;
  cursor: pointer;
  color: ${darkGrey};
  border-radius: 2px;
  border: 2px solid ${darkGrey};
  padding: 0.87rem;
  margin: 2rem 0.5rem;
  text-align: center;
  width: 20rem;
  @media (max-width: 1400px) {
    width: 13rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    align-self: center;
  }
`;
