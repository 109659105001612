import {fetchEntitiesIfNeeded} from 'core/api/actions.entities';
import redirectIfPartner from 'core/libs/hoc/redirectIfPartner';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle, mapProps} from 'recompose';
import {getEligiblesCertificates} from '../actions/certificates';
import {CertificatesWrapper} from '../components/certificates';

const {PARTNER} = process.env;

const Certificates = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {availableCertificates, paidCertificates} = props;
  return (
    <>
      <Head
        name={formatMessage({id: 'certificates'})}
        description={formatMessage({id: 'certificates_description'})}
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_certificates'
              : 'partner_helmet_certificates',
        })}
      />
      <div className="w-100 bg-darker-primary-blue tr-ns tc">
        <div className="w-80-ns w-90 center pv5 tr-ns tc white">
          <h4 className="f3 b pb4" data-cy="certificates-title">
            <FormattedMessage id="certificates" />
          </h4>
          <h1 className="f4" data-cy="certificates-description">
            <FormattedMessage id="certificates_description" />
          </h1>
        </div>
      </div>
      <Container>
        <CertificatesWrapper
          title="certificates_available"
          certificates={availableCertificates}
        />
        <CertificatesWrapper
          paid
          certificates={paidCertificates}
          title="certificates_obtained"
        />
      </Container>
    </>
  );
};

Certificates.propTypes = {
  availableCertificates: PropTypes.array.isRequired,
  paidCertificates: PropTypes.array.isRequired,
};

const mapStateToProps = ({entities, user}) => ({
  availableCertificates: user.certificates.availableCertificates,
  certificates: entities.certificates || {status: 'not fetched'},
  profile: user.profile,
});
const mapDispatchToProps = {
  fetchEntities: fetchEntitiesIfNeeded,
  fetchEligiblesCertificates: getEligiblesCertificates,
};

const enhance = compose(
  redirectIfPartner,
  connect(mapStateToProps, mapDispatchToProps),
  spinnerWhileLoading(({profile}) => !profile.fetched),
  lifecycle({
    componentDidMount() {
      const {
        fetchEntities,
        fetchEligiblesCertificates,
        profile: {
          data: {id},
        },
      } = this.props;
      fetchEligiblesCertificates(id);
      fetchEntities('certificates');
    },
  }),
  spinnerWhileLoading(
    ({availableCertificates, certificates}) =>
      availableCertificates.status !== 'fetched' ||
      certificates.status !== 'fetched',
  ),
  mapProps(({availableCertificates, certificates, intl}) => ({
    availableCertificates: availableCertificates.data,
    paidCertificates: Object.values(certificates).filter(
      (certificate) => typeof certificate === 'object',
    ),
  })),
);

export default enhance(Certificates);
