import {fetchEntitiesIfNeeded} from 'core/api/actions.entities';
import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withStateHandlers,
} from 'recompose';
import {
  createCertificate,
  getEligiblesCertificates,
} from '../../actions/certificates';
import {
  getInvoiceID,
  getPaymentURL,
  resetPaymentData,
} from '../../actions/payment';
import CertificatePreview from './CertificatePreview';
import ConfirmationUserNamePopup from './ConfirmationUserNamePopup';
import {bgBlueLow} from './styles.scss';

const CertificateForm = ({
  availableCertificatesIsLoading,
  coupon_code,
  first_name,
  form_coupon_code,
  handleApplyCoupon,
  handleChange,
  handleSubmit,
  last_name,
  loading,
  name,
  price,
  requested_coupon_code,
  total_price,
  confirmUserName,
  toggleConfirmUserNamePopup,
}) => (
  <div className="pv4">
    <h2 className="mb3 ltr">
      <FormattedMessage id="certificate_fill_form" />
    </h2>
    <div className={`pv3 ph4 cf ${bgBlueLow}`}>
      <div className="fr w-50-ns w-100-m w-100 mb0-ns mb3-m mb3">
        <div>
          <p className="pv3 fw6">
            <FormattedMessage id="label_first_name" />
          </p>
          <input
            aria-label="First name"
            type="text"
            className="input-reset pv3 pr3 pl2 bw0 br2 w-100 tr"
            name="first_name"
            onChange={handleChange}
            value={first_name}
          />
        </div>
        <div>
          <p className="pv3 fw6">
            <FormattedMessage id="label_last_name" />
          </p>
          <input
            aria-label="Last name"
            type="text"
            className="input-reset pv3 pr3 pl2 bw0 br2 w-100 tr"
            name="last_name"
            onChange={handleChange}
            value={last_name}
          />
        </div>
        <div className="pv2 cf">
          <p className="f5 pv3">
            <FormattedMessage id="price" />
            <span
              className={`ph2 fw6 ${price !== total_price ? 'strike' : ''}`}
            >
              {price}$
            </span>
            {price === 0 && (
              <span>
                ( <FormattedMessage id="certificate_price_note" /> )
              </span>
            )}
          </p>
          {price !== total_price && (
            <p className="f5">
              <FormattedMessage id="total_price" />
              <span className="ph2 fw6">{total_price}$</span>
            </p>
          )}
        </div>
        <div className="flex justify-between items-center pv3">
          <p className="b">
            {loading && <FormattedMessage id="certificate_loading_redirect" />}
            {availableCertificatesIsLoading && (
              <FormattedMessage id="certificate_loading_coupon" />
            )}
          </p>
          <ConfirmationUserNamePopup
            confirmUserName={confirmUserName}
            toggleConfirmUserNamePopup={toggleConfirmUserNamePopup}
            buyCertificate={handleSubmit}
            total_price={total_price}
            loading={loading}
            firstName={first_name}
            lastName={last_name}
          />
        </div>
      </div>
      <div className="fl w-50-ns w-100-m w-100 pr3-ns pr0-m pr0">
        <CertificatePreview
          name={`${first_name} ${last_name}`}
          trackName={name}
        />
      </div>
    </div>
  </div>
);

CertificateForm.propTypes = {
  availableCertificatesIsLoading: PropTypes.bool.isRequired,
  coupon_code: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  form_coupon_code: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleApplyCoupon: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  last_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  requested_coupon_code: PropTypes.string,
  total_price: PropTypes.number.isRequired,
  confirmUserName: PropTypes.bool,
  toggleConfirmUserNamePopup: PropTypes.func,
};

const mapStateToProps = ({user: {certificates, payment, profile}}) => ({
  certificateCreation: certificates.certificateCreation,
  initial_first_name: profile.data.first_name,
  initial_last_name: profile.data.last_name,
  invoiceID: payment.invoiceID,
  paymentURL: payment.paymentURL,
  userId: profile.data.id,
});

const mapDispatchToProps = {
  createCertificate,
  fetchEntities: fetchEntitiesIfNeeded,
  getInvoiceID,
  getPaymentURL,
  getEligiblesCertificates,
  resetPaymentData,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({coupon_code, initial_first_name, initial_last_name}) => ({
      form_coupon_code: coupon_code == null ? '' : coupon_code,
      first_name: initial_first_name,
      last_name: initial_last_name,
    }),
    {
      handleChange: () => (event) => ({
        [event.target.name]: event.target.value,
      }),
    },
  ),
  withHandlers({
    createCertificateWithData: ({
      createCertificate: dCreateCertificate,
      first_name,
      form_coupon_code,
      last_name,
      id,
    }) => (patch) => {
      dCreateCertificate({
        coupon_code: form_coupon_code,
        first_name,
        last_name,
        patch,
        track: id,
      });
    },
  }),
  withHandlers({
    handleSubmit: ({createCertificateWithData}) => (e) => {
      e.preventDefault();
      createCertificateWithData();
    },
    handleApplyCoupon: ({
      form_coupon_code,
      getEligiblesCertificates: dGetEligiblesCertificates,
      userId,
    }) => (e) => {
      e.preventDefault();
      dGetEligiblesCertificates(userId, form_coupon_code);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        certificateCreation,
        createCertificateWithData,
        fetchEntities,
        getInvoiceID: dGetInvoiceID,
        getPaymentURL: dGetPaymentURL,
        invoiceID,
        paymentURL,
        resetPaymentData: dResetPaymentData,
      } = this.props;
      // THIS IS THE NORMAL PROCEDURE
      // we create the certificate
      if (
        certificateCreation.status === 'fetched' &&
        prevProps.certificateCreation.status !== 'fetched'
      ) {
        dGetInvoiceID(certificateCreation.data.order_no);
      }
      // we ask the invoice id
      if (
        invoiceID.status === 'fetched' &&
        prevProps.invoiceID.status !== 'fetched' &&
        invoiceID.data.count !== 0
      ) {
        setTimeout(() => dGetPaymentURL(invoiceID.data.results[0].id), 500);
      }
      // we redirect to tap
      if (
        paymentURL.status === 'fetched' &&
        prevProps.paymentURL.status === 'fetching'
      ) {
        // received the TAP payment url
        if (paymentURL.data.payment_url) {
          window.location.href = paymentURL.data.payment_url;
        } else {
          notification({
            id: 'certificate_already_paid',
            type: 'success',
          });
          setTimeout(() => {
            fetchEntities('certificates', true);
            navigate('/account/certificates');
            dResetPaymentData();
          }, 7000);
        }
      }

      // THIS HAPPENS IF THE USER HAS ALREADY CREATED A CERTIFICATE BEFORE
      if (
        certificateCreation.status === 'rejected' &&
        prevProps.certificateCreation.status !== 'rejected' &&
        certificateCreation.data
      ) {
        const {data} = certificateCreation;
        if (data.is_paid && data.is_paid[0] === 'True') {
          notification({
            id: 'certificate_already_paid',
            type: 'success',
          });
          setTimeout(() => {
            fetchEntities('certificates', true);
            navigate('/account/certificates');
            dResetPaymentData();
          }, 7000);
        } else if (data.order_no && data.order_no[0]) {
          // WE UPDATE CERTIFICATE VALUES
          createCertificateWithData(true);
        }
      }
    },
  }),
  withProps(({certificateCreation, invoiceID, paymentURL}) => ({
    loading:
      certificateCreation.status === 'fetching' ||
      invoiceID.status === 'fetching' ||
      paymentURL.status === 'fetching' ||
      paymentURL.status === 'fetched',
  })),
  withStateHandlers(
    {confirmUserName: false},
    {
      toggleConfirmUserNamePopup: ({confirmUserName}) => () => {
        return {
          confirmUserName: !confirmUserName,
        };
      },
    },
  ),
);

export default enhance(CertificateForm);
