import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {checkbox, handler, darker} from './style.scss';

const CheckBoxWrapper = ({children, htmlFor, label}) => {
  if (label)
    return (
      <div className="flex flex-rows justify-between w-90 mt4">
        <label className="b f6 pl3" htmlFor={htmlFor}>
          {label}
        </label>
        <div className="mh2" />
        {children}
      </div>
    );
  return children;
};

const CheckBox = ({label, field = {}, className = '', dark}) => {
  return (
    <CheckBoxWrapper htmlFor={field.name} label={label}>
      <div
        className={cx('relative dib v-mid', checkbox, className, {
          [darker]: dark,
        })}
      >
        <input
          id={field.name}
          className="pointer absolute top-0 left-0 w-100 h-100 z-1 o-0"
          type="checkbox"
          checked={!!field.value}
          {...field}
        />
        <div className={`relative ${handler}`}>
          <span className="absolute left-0 bg-white br-pill" />
        </div>
      </div>
    </CheckBoxWrapper>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
  field: PropTypes.object.isRequired,
};

export default CheckBox;
