import notification from 'core/libs/helpers/notification';
import redirectIfPartner from 'core/libs/hoc/redirectIfPartner';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import {navigate} from 'gatsby';
import Container from 'modules/common/Container';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle, mapProps, setPropTypes} from 'recompose';
import {getEligiblesCertificates} from '../actions/certificates';
import {
  CertificateForm,
  CertificateIntro,
  CertificateSkills,
} from '../components/certificate';

const Certificate = (props) => {
  const title = (
    <div>
      <FormattedMessage id="certificate" />
      {` ${props.name}`}
    </div>
  );
  return (
    <div>
      <div className="w-100 bg-darker-primary-blue tr-ns tc">
        <div className="w-80-ns w-90 center pv5 tr-ns tc white">
          <h1 className="f3 b">{title}</h1>
        </div>
      </div>
      <Container>
        <div className="pb3 tr-ns tc">
          <CertificateIntro title={title} />
          <CertificateSkills />
          <CertificateForm {...props} />
        </div>
      </Container>
    </div>
  );
};

Certificate.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapStateToProps = ({user}) => ({
  availableCertificates: user.certificates.availableCertificates,
  profile: user.profile,
});

const mapDispatchToProps = {
  fetchEligiblesCertificates: getEligiblesCertificates,
};

const enhance = compose(
  redirectIfPartner,
  connect(mapStateToProps, mapDispatchToProps),
  spinnerWhileLoading(({profile}) => !profile.fetched),
  lifecycle({
    componentDidMount() {
      const {
        fetchEligiblesCertificates,
        profile: {
          data: {id},
        },
      } = this.props;
      fetchEligiblesCertificates(id);
    },
  }),
  spinnerWhileLoading(({availableCertificates}) => {
    if (availableCertificates.data.length === 0) {
      if (availableCertificates.status === 'fetched') {
        // The user have no certificate available
        navigate('/account/certificates');
        notification({id: '404'});
        return true;
      }
      // still loading
      return true;
    }
    return false;
  }),
  setPropTypes({
    trackId: PropTypes.string.isRequired,
  }),
  mapProps(({availableCertificates, trackId}) => {
    const certificate = availableCertificates.data.find(
      ({id}) => id == trackId, // eslint-disable-line eqeqeq
    );
    if (certificate === undefined) {
      navigate('/account/certificates');
      notification({id: '404'});
      return {};
    }
    return {
      ...certificate,
      requested_coupon_code: availableCertificates.requested_coupon_code,
      availableCertificatesIsLoading:
        availableCertificates.status !== 'fetched',
    };
  }),
);

export default enhance(Certificate);
